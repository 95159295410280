import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { now } from '@internationalized/date';
import { TimePicker, SimpleTimePicker, NativeTimePicker, nativeDateToTimeValue, nativeDateToDateValue, timeOrDateValueToNativeDate } from '@entur/datepicker';
import { SmallAlertBox } from '@entur/alert';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { CodeBlock } from '~/components/CodeBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [time, setTime] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <TimePicker\n      label=\"Tid\"\n      selectedTime={time}\n      onChange={time => setTime(time)}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      TimePicker,
      SimpleTimePicker,
      NativeTimePicker,
      nativeDateToTimeValue,
      nativeDateToDateValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor style={{
      display: 'flex',
      justifyContent: 'center'
    }} mdxType="Playground">
  {() => {
        const [time, setTime] = React.useState(now('Europe/Oslo'));
        return <TimePicker label="Tid" selectedTime={time} onChange={time => setTime(time)} mdxType="TimePicker" />;
      }}
    </Playground>
    <h2 {...{
      "id": "bruk"
    }}>{`Bruk`}</h2>
    <p>{`TimePicker hjelper brukeren med å velge et tidspunkt. Den bruker pakken `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/internationalized/date/"
      }}>{`@internationalized/date`}</a>{` til håndtering av dato og tid, inkludert tidssoner.
TimePicker-en har også støtte for ulike locals og språk. `}<inlineCode parentName="p">{`react-aria`}</inlineCode>{` brukes i bakgrunnen, `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/useTimeField.html"
      }}>{`les mer her`}</a>{`.`}</p>
    <SmallAlertBox variant="information" mdxType="SmallAlertBox">
  <div>
    <strong>OBS:</strong> hjelpefunksjoner fra{' '}
    <code className="eds-code-text">@internationalized/date</code> (typ.{' '}
    <code className="eds-code-text">now()</code> og{' '}
    <code className="eds-code-text">isWeekend()</code>) er ikke inkludert i{' '}
    <code className="eds-code-text">@entur/datepicker</code>, legg til{' '}
    <code className="eds-code-text">@internationalized/date</code> i repo ditt
    for å bruke dem.
  </div>
    </SmallAlertBox>
    <h3 {...{
      "id": "språk-og-locale"
    }}>{`Språk og locale`}</h3>
    <p>{`Språk og locals er støttet gjennom to metoder: All automatisk tilpassing av språk skjer gjennom prop-en `}<inlineCode parentName="p">{`locale`}</inlineCode>{` eller `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/I18nProvider.html"
      }}>{`react-aria`}</a>{` sin `}<inlineCode parentName="p">{`<I18nProvider />`}</inlineCode>{`.
`}<inlineCode parentName="p">{`locale`}</inlineCode>{` støtter strenger på BCP 47-formatet, eks. `}<inlineCode parentName="p">{`no-NO`}</inlineCode>{` for norsk. Her finner du en `}<a parentName="p" {...{
        "href": "https://www.techonthenet.com/js/language_tags.php"
      }}>{`liste over BCP 47-koder`}</a>{`.`}</p>
    <p>{`I tillegg er det noen ledetekster og `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`-er som må sendes inn manuelt. Dette gjelder `}<inlineCode parentName="p">{`leftArrowButtonAriaLabel`}</inlineCode>{` og `}<inlineCode parentName="p">{`rightArrowButtonAriaLabel`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "timepicker-tilpasset-usa"
    }}>{`TimePicker tilpasset USA`}</h4>
    <Playground __position={3} __code={'() => {\n  const [time, setTime] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <TimePicker\n      label=\"Time\"\n      selectedTime={time}\n      onChange={time => setTime(time)}\n      locale=\"en-US\"\n      leftArrowButtonAriaLabel=\"Subtract 30 minutes\"\n      rightArrowButtonAriaLabel=\"Add 30 minutes\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      TimePicker,
      SimpleTimePicker,
      NativeTimePicker,
      nativeDateToTimeValue,
      nativeDateToDateValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor hideContrastOption style={{
      justifyContent: 'start',
      width: 'fit-content'
    }} mdxType="Playground">
  {() => {
        const [time, setTime] = React.useState(now('Europe/Oslo'));
        return <TimePicker label="Time" selectedTime={time} onChange={time => setTime(time)} locale="en-US" leftArrowButtonAriaLabel="Subtract 30 minutes" rightArrowButtonAriaLabel="Add 30 minutes" mdxType="TimePicker" />;
      }}
    </Playground>
    <h3 {...{
      "id": "tidssoner"
    }}>{`Tidssoner`}</h3>
    <p>{`TimePicker støtter tidssonehåndtering for å sikre lik opplevelse på tvers av tidssoner. Dette håndteres ved `}<inlineCode parentName="p">{`@internationalized/date`}</inlineCode>{` sitt `}<inlineCode parentName="p">{`ZonedDateTime`}</inlineCode>{`-objekt, `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/internationalized/date/ZonedDateTime.html"
      }}>{`les mer om hvordan opprette og bruke tidssonefunksjonalitet her`}</a>{`.`}</p>
    <h3 {...{
      "id": "bruke-js-date-i-stedet-for-internationalizeddate"
    }}>{`Bruke JS Date i stedet for @internationalized/date`}</h3>
    <p>{`Hvis du ikke har mulighet til å bruke `}<inlineCode parentName="p">{`@internationalized/date`}</inlineCode>{`, kan du bruke konverteringsfunksjonene: `}<inlineCode parentName="p">{`nativeDateToTimeValue`}</inlineCode>{` og `}<inlineCode parentName="p">{`timeValueToNativeDate`}</inlineCode>{`. Disse konverterer
mellom `}<inlineCode parentName="p">{`@internationalized/date`}</inlineCode>{` sine tre tidstyper: `}<inlineCode parentName="p">{`ZonedDateTime`}</inlineCode>{`, `}<inlineCode parentName="p">{`CalendarDateTime`}</inlineCode>{` og `}<inlineCode parentName="p">{`Time`}</inlineCode>{` (`}<inlineCode parentName="p">{`TimeValue`}</inlineCode>{` er en samling av disse tre typene) og Javascript sin `}<inlineCode parentName="p">{`Date`}</inlineCode>{`.
Se API under, nærmere beskrivelse finnes i JSDocs for funksjonene:`}</p>
    <CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
  {`nativeDateToTimeValue: (date: Date | null, noDateOnlyTime?: boolean, timeZone?: string | undefined, offset?: number | undefined) => Time | CalendarDateTime | ZonedDateTime | null;
timeOrDateValueToNativeDate: (value: TimeValue | DateValue | null, timeZoneForCalendarDateTime?: string | undefined) => Date | null;`}
    </CodeBlock>
    <h4 {...{
      "id": "eksempel-på-bruk-av-js-date-med-datepicker"
    }}>{`Eksempel på bruk av JS-date med DatePicker`}</h4>
    <CodeBlock asExpandable expandableLabel="JS Date-eksempel" mdxType="CodeBlock">
  {`const [time, setTime] = useState(new Date());
  return (
    <TimePicker
      label="Choose time"
      selectedTime={nativeDateToTimeValue(time)}
      onChange={t => setTime(timeOrDateValueToNativeDate(t))}
    />
  );`}
    </CodeBlock>
    <CodeBlock asExpandable expandableLabel="JS Date med tidssone-eksempel" mdxType="CodeBlock">
  {`const [time, setTime] = useState(new Date());
  return (
    <TimePicker
      label="Choose time"
      selectedTime={
        nativeDateToTimeValue(
          time,
          false,
          'Europe/Oslo',
        )
      }
      onChange={t => setTime(timeOrDateValueToNativeDate(t))}
    />
  );`}
    </CodeBlock>
    <h3 {...{
      "id": "bruk-sammen-med-datepicker"
    }}>{`Bruk sammen med DatePicker`}</h3>
    <p>{`Du kan velge en dato sammen med tidspunktet på to ulike måter, enten ved å bruke en `}<inlineCode parentName="p">{`DatePicker`}</inlineCode>{` i kombinasjon med `}<inlineCode parentName="p">{`TimePicker`}</inlineCode>{`-en – se `}<a parentName="p" {...{
        "href": "/komponenter/skjemaelementer/datepicker#kombinasjon-med-timepicker"
      }}>{`kombo av Time- og DatePicker-eksempel på siden til DatePicker`}</a>{` –,
eller inline ved å bruke en `}<inlineCode parentName="p">{`DatePicker`}</inlineCode>{` med prop-en `}<inlineCode parentName="p">{`showTime`}</inlineCode>{` – se `}<a parentName="p" {...{
        "href": "/komponenter/skjemaelementer/datepicker#inline"
      }}>{`inline-eksempel på siden til DatePicker`}</a>{`.`}</p>
    <h3 {...{
      "id": "bruk-på-mobile-enheter"
    }}>{`Bruk på mobile enheter`}</h3>
    <p>{`TimePicker er fungerer like bra på mobile enheter også, men ønsker man en OS-spesifikk opplevelse på mobilen kan `}<inlineCode parentName="p">{`<NativeTimePicker />`}</inlineCode>{` benyttes. Her må dubenytte `}<inlineCode parentName="p">{`Date`}</inlineCode>{`-objektet til Javascript eller `}<a parentName="p" {...{
        "href": "#bruk-sammen-datepicker--bruk-med-js-date"
      }}>{`konvertere et TimeValue-objekt`}</a>{`.`}</p>
    <Playground __position={7} __code={'() => {\n  const [nativeTime, setNativeTime] = React.useState(new Date())\n  return (\n    <NativeTimePicker\n      label=\"Tid\"\n      style={{ width: \'15rem\' }}\n      value=\"10:30:00\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      TimePicker,
      SimpleTimePicker,
      NativeTimePicker,
      nativeDateToTimeValue,
      nativeDateToDateValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor hideContrastOption style={{
      justifyContent: 'start',
      width: 'fit-content'
    }} mdxType="Playground">
  {() => {
        const [nativeTime, setNativeTime] = React.useState(new Date());
        return <NativeTimePicker label="Tid" style={{
          width: '15rem'
        }} value="10:30:00" mdxType="NativeTimePicker" />;
      }}
    </Playground>
    <h3 {...{
      "id": "universell-utforming"
    }}>{`Universell utforming`}</h3>
    <p>{`TimePicker bruker `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/useTimeField.html"
      }}>{`react-aria`}</a>{` i bakgrunnen somsørger for gjennomgående støtte for universell utforming.
Hvert tidssegment er tilgjengelig med tastaturet og alle interagerbare elementer har aria-beskrivelser.`}</p>
    <h4 {...{
      "id": "hvordan-oppfylle-uu-krav"
    }}>{`Hvordan oppfylle UU-krav`}</h4>
    <p>{`Hvis du endrer `}<inlineCode parentName="p">{`locale`}</inlineCode>{` (dvs. språk) må du sende inn verdier på riktig språk til `}<inlineCode parentName="p">{`leftArrowButtonAriaLabel`}</inlineCode>{`- og `}<inlineCode parentName="p">{`rightArrowButtonAriaLabel`}</inlineCode>{`-props-ene.`}</p>
    <h3 {...{
      "id": "mer-kompakt-fritektsvariant-b2b"
    }}>{`Mer kompakt fritektsvariant (B2B)`}</h3>
    <p>{`For grensesnitt der et kompakt skjemafelt og mulighet til å kopiere og lime inn fullstendige tidspunkt er viktig kan man bruke `}<inlineCode parentName="p">{`SimpleTimePicker`}</inlineCode>{`. Denne varianten inneholder
mindre funksjonalitet enn `}<inlineCode parentName="p">{`TimePicker`}</inlineCode>{` – den har f.eks ikke `}<inlineCode parentName="p">{`locale`}</inlineCode>{` og fullstendig UU-støtte –, men bygger på samme tidsobjekt. Man interagerer med feltet ved å skrive inn tidspunkt på et av følgende format (t: time, m: minutt, s:sekund): tmm, ttmm, tt:mm, tmmss, ttmmss, t:mm:ss, tt:mm:ss.
Hvis du ønsker å vise sekunder i feltet kan du bruke `}<inlineCode parentName="p">{`showSeconds`}</inlineCode>{`-prop-en.`}</p>
    <Playground __position={8} __code={'() => {\n  const [time, setTime] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <>\n      <SimpleTimePicker\n        label=\"B2B time\"\n        selectedTime={time}\n        onChange={time => setTime(time)}\n        style={{ width: \'7rem\' }}\n      />\n      <br />\n      <SimpleTimePicker\n        label=\"B2B time\"\n        selectedTime={time}\n        onChange={time => setTime(time)}\n        showSeconds\n        style={{ width: \'8.5rem\' }}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      TimePicker,
      SimpleTimePicker,
      NativeTimePicker,
      nativeDateToTimeValue,
      nativeDateToDateValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor hideContrastOption style={{
      alignItems: 'start'
    }} mdxType="Playground">
  {() => {
        const [time, setTime] = React.useState(now('Europe/Oslo'));
        return <>
        <SimpleTimePicker label="B2B time" selectedTime={time} onChange={time => setTime(time)} style={{
            width: '7rem'
          }} mdxType="SimpleTimePicker" />
        <br />
        <SimpleTimePicker label="B2B time" selectedTime={time} onChange={time => setTime(time)} showSeconds style={{
            width: '8.5rem'
          }} mdxType="SimpleTimePicker" />
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "timepicker-"
    }}>{`<TimePicker /`}{`>`}</h3>
    <ImportStatement imports="TimePicker" mdxType="ImportStatement" />
    <Props of={TimePicker} mdxType="Props" />
    <h3 {...{
      "id": "simpletimepicker-"
    }}>{`<SimpleTimePicker /`}{`>`}</h3>
    <ImportStatement imports="SimpleTimePicker" mdxType="ImportStatement" />
    <Props of={SimpleTimePicker} mdxType="Props" />
    <h3 {...{
      "id": "nativetimepicker-"
    }}>{`<NativeTimePicker /`}{`>`}</h3>
    <ImportStatement imports="NativeTimePicker" mdxType="ImportStatement" />
    <Props of={NativeTimePicker} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      